import React from "react";
import { Link } from "react-router-dom";
import style from "./Banner.module.css";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import lng from "../../../utils/language";
import Box from '@mui/material/Box';

export default function FixedBanner(props) {
    console.log(props);
  const { authenticated } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);
  return (
    <div className={style.homeBanner}>
      <Box
        className={style.homeBannerImg}
        sx={
            props.otherMobile ? 
            {
                backgroundImage: {
                    md: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),url("${props.image}")`, 
                    xs: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),url("${props.imageMobile}")`
                }
            } 
            : 
            {
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),url("${props.image}")`
            }
        }
      >
        <Box className="container">
          <Box  className={style.bannerText}
           sx={{
            bottom: '20%'
        }}
          >  
            {/* <Typography variant="h1" marginBottom={3} sx={[{whiteSpace: 'pre-line', fontSize: {sm: '47px', xs: '36px'}}, language === 'alb' ? {width: {sm:'498px !important', xs: '100% !important'}} : {width: {sm:'498px !important', xs: '100% !important'}}]}>
                {
                    lng[language]["adventure"]
                }
            </Typography> */}
            {/* <Typography component={Link} to="/reservation" className="btn" sx={{borderRadius: 40}}>{lng[language]["bookNow"]}</Typography> */}
            <div className={style.bannerButtons}>
                {
                    props.noButton ?
                    ''
                    :
                    <>
                        {/* <Typography component={Link} to="/reservation/739676" className="btn" sx={{borderRadius: 40}}>{lng[language]["bookKarting"]}</Typography> */}
                        {!authenticated && <Typography component={Link} to="/register" className="whiteBtn" sx={{borderRadius: 40}}>{lng[language]["register"]}</Typography>} 
                    </>
                }
              
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
