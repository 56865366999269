import { useSelector } from "react-redux";
import style from "./Homepage.module.css";
import FixedBanner from "../../../components/general/banner/FixedBanner";
import Grid from "../../../layout/grid/Grid";
import LinkCard from "../../../components/card/LinkCard";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import lng from "../../../utils/language";
import Box from '@mui/material/Box';
import PartyBanner from "../../../components/general/banner/PartyBanner";
import Button from '@mui/material/Button';
import AppBanner from "../../../components/general/banner/AppBanner";
import Text from "../../../components/text/Text";
import Videos from "../../../components/general/videos/Videos";
import AddBanner from "../../../components/general/banner/AddBanner";

function Homepage() {

    const { language } = useSelector((state) => state.language);

    const data = [
        {
            homepage: true,
            url: "/activities/karting",
            desc: "Karting",
            imgUrl: "https://hyper-active.com/images/homepage/compressed/gokart-h.png",
            imgAlt: "Go Kart",
            comingSoon: false
        },
        // {
        //     homepage: true,
        //     url: "/activities/hyperstrike",
        //     desc: "HyperStrike",
        //     imgUrl: "https://hyper-active.com/assets/reservation/HyperStrike.png",
        //     imgAlt: "HyperStrike",
        //     comingSoon: false
        // },
        {
            homepage: true,
            url: "/activities/laser",
            desc: "Laser Tag",
            imgUrl: "https://hyper-active.com/images/homepage/compressed/lasertag-h.png",
            imgAlt: "Laser Tag",
            comingSoon: false
        },
        {
            homepage: true,
            url: "/activities/escape",
            desc: "Escape Rooms",
            imgUrl: "https://hyper-active.com/images/homepage/compressed/escaperoom-h.png",
            imgAlt: "Escape Rooms",
            comingSoon: false
        },
        {
            homepage: true,
            url: "/activities/arcade",
            desc: "Arcade Games",
            imgUrl: "https://hyper-active.com/images/homepage/compressed/arcade-h.png",
            imgAlt: "Arcade",
            comingSoon: false
        },
        {
            homepage: true,
            url: "/activities/bowling",
            desc: "Bowling",
            imgUrl: "https://hyper-active.com/images/homepage/compressed/bowling-h.png",
            imgAlt: "Bowling",
            comingSoon: false
        },
        {
            homepage: true,
            url: "/activities/virtual",
            desc: "Virtual Reality",
            imgUrl: "https://hyper-active.com/images/homepage/compressed/vr-h.png",
            imgAlt: "VR",
            comingSoon: false
        },
        {
            homepage: true,
            url: "/activities/eli",
            desc: "Lodra",
            imgUrl: "https://hyper-active.com/images/homepage/compressed/lodra-h.png",
            imgAlt: "lodra",
            comingSoon: false
        },
        // {
        //     homepage: true,
        //     url: "/activities/hyperstrike",
        //     desc: "HyperStrike",
        //     imgUrl: "https://hyper-active.com/assets/images/new_main_banner.png",
        //     imgAlt: "Arcade",
        //     comingSoon: false
        // },
        // {
        //     homepage: true,
        //     url: "/activities/recharge",
        //     desc: "Biliardo",
        //     imgUrl: "https://hyper-active.com/images/homepage/compressed/biliard-h.png",
        //     imgAlt: "Biliardo",
        //     comingSoon: false
        // },
        // {
        //     homepage: true,
        //     url: "/activities/recharge",
        //     desc: "Pikado",
        //     imgUrl: "https://hyper-active.com/images/homepage/compressed/pikado-h.png",
        //     imgAlt: "Pikado",
        //     comingSoon: false
        // },
        // {
        //     homepage: true,
        //     url: "/activities/recharge",
        //     desc: "HyperStrike",
        //     imgUrl: "https://hyper-active.com/images/homepage/compressed/HyperStrike.png",
        //     imgAlt: "Axe Throwing",
        //     comingSoon: true
        // },

    ];

    const rooms = [
        {
            homepage: true,
            url: "/activities/party",
            imgUrl: "https://hyper-active.com/images/content/Private-Parties.png",
            desc: "Private Parties",
        },
        {
            homepage: true,
            url: "/activities/bussiness",
            imgUrl: "https://hyper-active.com/images/content/Business.png",
            desc: "Businness",
        },
        {
            homepage: true,
            url: "/activities/recharge",
            imgUrl: "https://hyper-active.com/images/content/HyperActive_Catalogue-4.png",
            desc: "Food",
        },
        // {
        //     homepage: true,
        //     url: "/business1",
        //     imgUrl: "https://hyper-active.com/images/content/Kaffe.png",
        //     desc: "Food",
        // },
    ];

    return (
        <>
            <FixedBanner
                imageAlt="Home Banner"
                image="https://hyper-active.com/assets/images/new_main_banner.png"
                otherMobile={true}
                imageMobile="https://hyper-active.com/assets/images/mobile_banner.png"
            />
            <Box
                style={{ backgroundImage: `url(../assets/images/circleBck.png)` }}
                className={style.circleBck}
            >
                <Box className="container">
                    <div className={style.wrapper}>
                        <Text
                            //DATA 
                            header={lng[language]['homePageLandingTitle']}
                            desc={lng[language]['homePageLandingDesc']}
                            // STYLING
                            containerStyling={{ textAlign: 'center', marginX: 'auto' }}
                            headerStyle={{ fontSize: { md: '52px', xs: '30px' }, fontWeight: 900, marginBottom: '30px' }}
                            descStyling={{ fontSize: { md: '18px', xs: '16px' }, width: { md: '879px', sm: '80%', xs: '100%' }, marginX: 'auto', textAlign: 'center' }}
                        />
                    </div>
                    {data && (
                        <Box className={style.wrapper}>
                            <Box
                                sx={{
                                    rowGap: '20px',
                                    display: 'grid',
                                    columnGap: '20px',
                                    gridTemplateColumns: { sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }
                                }}
                            >
                                {data.map((data, index) => (
                                    <LinkCard data={data} key={index} />
                                ))}
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box className={["container", style.wrapper]}
                    sx={{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative', marginTop: '100px !important' }}
                >
                    <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                    <Videos id={'TAj2K1jYec8'} />
                </Box>

                <Box sx={{
                    marginY: '100px'
                }}>
                            <AddBanner
                            mainContainer={{
                                height: '400px',
                                paddingY: 0,
                                '.imgCon': {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: 1,
                                    backgroundColor: '#AF46FA',
                                    '&::before': {
                                        content: "none"
                                    },
                                    'img': {
                                        objectFit: {
                                            sm: '',
                                            xs: 'cover'
                                        }
                                    }
                                }
                            }}
                                descStyle={{
                                    width: {
                                        sm: '470px',
                                        xs: '100%'
                                    }
                                }}
                                header={lng[language]['girlheader']}
                                desc={lng[language]['girldesc']}
                                imageUrl={"https://hyper-active.com/images/girlWj.png"}
                            />
                        </Box>

                <Box className="container">
                    {rooms && (
                        <div className={style.wrapper}>
                            <Text header={lng[language]['otherActivities']} containerStyling={{ textAlign: 'center' }} headerStyle={{ fontSize: { md: '56px', xs: '30px' }, fontWeight: 900, marginBottom: '80px' }} />
                            <Grid no={"Activity"}>
                                {rooms.map((data, index) => (
                                    <LinkCard data={data} key={index} />
                                ))}
                            </Grid>
                        </div>
                    )}
                </Box>

                <Box className={style.wrapper} sx={{ paddingTop: '20px' }}>
                    <PartyBanner
                        header={lng[language]['hyperBirthdayHeader']}
                        desc={lng[language]['hyperBirthdayDesc']}
                        buttonTxt={lng[language]['hyperBirthdayBtn']}
                        link={"/activities/party"}
                        imageUrl={"https://hyper-active.com/images/content/bIRTHDAY.png"}
                        containerStyle={{
                            width: { sm: "454px", xs: '90%' },
                            color: "#FFF",
                            background: "linear-gradient(180deg, rgba(240,60,70,1) 0%, rgba(250,160,0,1) 100%)",
                            padding: 3,
                            borderRadius: "40px",
                            display: "grid",
                            gridTemplateRows: "1fr 70px 60px",
                            rowGap: "20px",
                        }}
                    />
                </Box>

                <Box className={[style.wrapper, "container"]} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h3" marginY={2} fontWeight={'bold'}>{lng[language]["rezerveNow"]}</Typography>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }} textAlign={'center'} marginY={2}>{lng[language]["rezerveNowDesc"]}</Typography>
                    <Button
                        variant="contained"
                        sx={{
                            marginY: 2,
                            borderRadius: '20px',
                            paddingY: 1.5,
                            paddingX: 5,
                            backgroundColor: '#321EFF',
                            fontWeight: 'normal',
                            transition: '.3s all',
                            "&:hover": {
                                backgroundColor: '#FFF',
                                color: '#321EFF',
                                scale: '1.1'
                            }
                        }}
                        LinkComponent={Link}
                        to="/reservation"
                    >
                        {lng[language]['bookNow']}
                    </Button>
                </Box>

                <Box sx={{
                    marginY: '100px'
                }}>
                            <AddBanner
                            mainContainer={{
                                height: '400px',
                                paddingY: 0,
                                '.textContainer': {
                                    display: 'grid',
                                    gridTemplateRows: 'auto auto',
                                },
                                '.imgCon': {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: 1,
                                    backgroundColor: '#070f40',
                                    '&::before': {
                                        content: "none"
                                    }
                                }
                            }}
                                descStyle={{
                                    
                                    width: {
                                        sm: '470px',
                                        xs: '100%'
                                    }
                                }}
                                header={lng[language]['boyheader']}
                                desc={lng[language]['boydesc']}
                                imageUrl={"https://hyper-active.com/images/Mask Group 194.png"}
                            />
                        </Box>

                <Box className={[style.wrapper, "container"]}>
                    <Box sx={{ marginTop: '50px' }}>
                        <Typography variant="h4" textAlign={'center'}>Hyperdrive Scoreboard</Typography>
                        <Box sx={{ width: { lg: '60%', xs: '80%' }, marginX: 'auto', marginY: 5, borderRadius: '50px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box component={'img'} width={'100%'} height={'100%'} sx={{ objectFit: 'contain' }} src="https://hyper-active.com/images/hyperdrive/Rectangle 348.png" alt="Karting Score" />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                                className={'btn'}
                                component={Link}
                                to={'/karting/scoreboard'}
                            >
                                {lng[language]['checkResults']}
                            </Box>
                        </Box>
                    </Box>
                    <Box className={style.priceList}>
                        <p>Listën e cmimeve mund ta gjeni këtu</p>
                        <Link to="/activities/price" className="btn">Shiko Listën e Cmimeve</Link>
                    </Box>

                    <Box className={[style.wrapper, "container"]}>
                        <AppBanner
                            header={lng[language]['hyperAppHeader']}
                            desc={lng[language]['hyperAppDesc']}
                            imgUrl={'../images/6582cf3c6d165.webp'}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Homepage;
